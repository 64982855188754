.number-keyboard {
  background-color: rgba(36, 36, 36, 0.5); }
  .number-keyboard .value-display {
    background-color: white;
    margin: 50px auto;
    height: 35px;
    width: 200px;
    font-size: 35px; }
  .number-keyboard .keypad-buttons {
    width: 4em;
    text-align: center !important; }
