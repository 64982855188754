* {
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

html {
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: transparent; }

body {
  font: normal 20px Arial, Helvetica, sans-serif;
  background-color: transparent; }

h1, h3 {
  font: normal 32px 'Open Sans', Arial, Helvetica, sans-serif; }

.main-content {
  background-color: #dddddd;
  box-sizing: border-box;
  text-align: center;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  height: 100%; }

.main-content h3 {
  font-size: 32px;
  color: #5a96b9;
  padding-bottom: 20px; }

#app-title {
  user-select: text;
  font-size: 20px;
  color: #3a7699;
  padding-top: 15px;
  padding-bottom: 10px; }

#tab-bar {
  position: absolute;
  top: calc(1.5em + 10px);
  left: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  display: none;
  height: 88px; }
  #tab-bar.visible {
    display: block; }

#all-ad-tags, #skyline-branches {
  overflow: scroll; }
  #all-ad-tags::-webkit-scrollbar, #skyline-branches::-webkit-scrollbar {
    width: 1em;
    height: 0em; }
  #all-ad-tags::-webkit-scrollbar-thumb, #skyline-branches::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 0px; }

.page {
  display: none; }
  .page.visible {
    display: block; }

.page {
  position: absolute;
  width: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  top: 138px;
  bottom: 0; }

.full-page {
  top: 0; }

#tab-bar {
  margin: 0;
  margin-left: auto;
  margin-right: auto; }

.tab-title {
  font: normal 32px 'Open Sans', Arial, Helvetica, sans-serif;
  color: #a2b0cc;
  padding: 0;
  padding-left: 25px;
  padding-right: 25px;
  display: inline-block; }
  .tab-title.selected {
    color: #5a96b9; }
  .tab-title.hasFocus {
    text-decoration: underline; }

.container-config-choice-item, .mode-config-choice-item, .fw-config-choice-cc, .env-choice-item, .user-id-choice-item,
.cancel-stream-item, .auto-pause-ads-item, .webmaf-item {
  display: inline-block; }
  .container-config-choice-item input, .container-config-choice-item label, .mode-config-choice-item input, .mode-config-choice-item label, .fw-config-choice-cc input, .fw-config-choice-cc label, .env-choice-item input, .env-choice-item label, .user-id-choice-item input, .user-id-choice-item label,
  .cancel-stream-item input,
  .cancel-stream-item label, .auto-pause-ads-item input, .auto-pause-ads-item label, .webmaf-item input, .webmaf-item label {
    pointer-events: none; }
  .container-config-choice-item.hasFocus label, .mode-config-choice-item.hasFocus label, .fw-config-choice-cc.hasFocus label, .env-choice-item.hasFocus label, .user-id-choice-item.hasFocus label,
  .cancel-stream-item.hasFocus label, .auto-pause-ads-item.hasFocus label, .webmaf-item.hasFocus label {
    text-decoration: underline; }

#ad-config-bar {
  position: absolute;
  font-size: 22px;
  color: #5a96b9;
  height: 50px;
  top: 88px;
  width: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  display: none; }
  #ad-config-bar.visible {
    display: block; }
  #ad-config-bar .user-id-choices-prompt, #ad-config-bar .cancel-stream-item, #ad-config-bar .auto-pause-ads-item, #ad-config-bar .webmaf-item {
    padding-left: 1.5em; }
  #ad-config-bar .webmaf-item {
    display: none; }

.tags-list {
  list-style: none;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  padding: 0; }

.creative-tag-divider {
  margin: 28px 12px 14px -1.25em;
  font-size: 165%;
  color: #a0a0a0; }
  .creative-tag-divider span {
    padding-left: 0.25em;
    padding-right: 0.25em; }
  .creative-tag-divider.hasFocus {
    color: black; }
    .creative-tag-divider.hasFocus span {
      background-color: #c5c5c5; }

.action-button {
  box-sizing: border-box;
  display: inline-block;
  cursor: pointer;
  position: relative;
  transition: 0.2s;
  text-align: left;
  font: normal 12px sans-serif;
  background-color: #ffffff;
  border: 1px solid #dbe3e7;
  border-radius: 3px;
  box-shadow: 1px 3px 1px rgba(0, 0, 0, 0.08);
  margin: 0 12px 14px 0;
  padding: 25px;
  font-size: 22px; }
  .action-button.hasFocus {
    background-color: #d5d5d5; }
  .action-button .label {
    font-weight: bold;
    text-overflow: ellipsis;
    margin: 0;
    text-decoration: none;
    color: #2B2E31; }
  .action-button .ad-tag-detail {
    font-weight: lighter;
    font-size: 75%;
    text-overflow: ellipsis;
    margin: 1%;
    text-decoration: none;
    color: #888888;
    bottom: 0;
    right: 0;
    position: absolute; }
  .action-button.ad-tag {
    vertical-align: middle; }
    .action-button.ad-tag .label {
      width: 20em; }
  .action-button.test-button {
    display: block;
    width: 20em; }
    .action-button.test-button .label {
      width: 20em; }
  .action-button.tag-restriction-button {
    padding: 10px;
    font-size: 20px; }
    .action-button.tag-restriction-button .label {
      font-weight: normal; }
  .action-button.branch-button {
    display: block;
    width: 40em; }
    .action-button.branch-button .label {
      width: 40em;
      margin-left: 1.5em;
      text-indent: -1.5em; }

#skyline-branches {
  width: 100%;
  text-align: center; }
  #skyline-branches .branch-list {
    display: inline-block;
    margin: 0 auto; }

#tag-restrictions p {
  font-size: 22px; }

#test-control {
  user-select: text;
  width: 100%;
  text-align: center; }
  #test-control p {
    text-align: left;
    margin: 0;
    margin-left: 15em;
    width: calc(100% - 20em); }
  #test-control .test-buttons {
    display: inline-block;
    margin: 0 auto;
    margin-top: 3em; }

#creative-draft-id {
  width: 100%;
  text-align: center; }
  #creative-draft-id .keypad-buttons {
    width: 4em;
    text-align: center; }
  #creative-draft-id .start-buttons {
    display: inline-block;
    width: 14em;
    text-align: center; }
  #creative-draft-id .start-row {
    position: relative;
    width: 310px;
    margin: 0 auto;
    padding: 0;
    overflow: visible; }
    #creative-draft-id .start-row .action-button {
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0; }
  #creative-draft-id .start-config {
    position: absolute;
    text-align: left;
    left: calc(100% + 20px);
    width: 30em;
    top: 0; }
    #creative-draft-id .start-config table {
      font-weight: bold;
      font-size: 22px;
      border-spacing: 1em; }
  #creative-draft-id #creative-draft-id-preview {
    display: inline-block;
    background-color: white;
    width: 15vh;
    height: 1.5em; }

#ad-display {
  user-select: text;
  padding-top: 0.5em;
  top: 1.5em;
  left: 0;
  overflow: hidden; }
  #ad-display #adEvents {
    padding-top: 1em;
    max-height: calc(100% - 13em);
    overflow: scroll; }
  #ad-display #adError {
    padding-top: 1em;
    color: salmon; }
  #ad-display #detail, #ad-display #adEventItems, #ad-display #adError {
    text-align: left;
    word-break: break-all;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto; }
    #ad-display #detail p, #ad-display #adEventItems p, #ad-display #adError p {
      text-indent: -1.5em;
      padding-left: 1.5em;
      width: auto; }

.input-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  background-color: rgba(36, 36, 36, 0.5); }

#error {
  pointer-events: none;
  position: absolute;
  left: 0;
  padding-top: 2em; }
  #error h3 {
    font-size: 40px; }
